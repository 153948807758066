import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="বাংলা" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  বাংলা
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }, {
        url: '/study-materials/bengali',
        name: 'বাংলা',
        isCurrentPage: true
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <BookList books={[{
        title: 'বাংলা বর্ণ চিনি ও বাংলা পড়তে শিখি (প্রেসেন্টেশন)',
        link: '/study-materials/bengali/know-and-learn-bengali-letters'
      }]} mdxType="BookList" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      